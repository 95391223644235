import React from 'react'
import { Link } from 'gatsby'
import { BlogPostFrontmatter, IMdx } from '../types'

type BlogPostsProps = {
  posts: IMdx<BlogPostFrontmatter>[]
}

export default function BlogPosts({ posts }: BlogPostsProps) {
  if (posts.length === 0) {
    return null
  }
  return (
    <ol className="">
      {posts.map(post => {
        const title = post.frontmatter.title

        return (
          <li key={post.frontmatter.slug}>
            <article
              className="my-4"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h2 className="text-2xl font-bold">
                  <Link
                    to={post.fields.urlpath}
                    itemProp="url"
                    className="focus:outline-none focus:ring focus:ring-gray-400 transition-shadow"
                  >
                    <span itemProp="headline">{title}</span>
                  </Link>
                </h2>
                <small className="uppercase text-gray-500">
                  {post.frontmatter.date}
                </small>
              </header>
              {/* <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                  }}
                  itemProp="description"
                />
              </section> */}
            </article>
          </li>
        )
      })}
    </ol>
  )
}
