import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from '../main-components/Layout'
import SEO from '../components/SEO'
import BlogPosts from '../main-components/BlogPosts'
import { INodes, IMdx, BlogPostFrontmatter } from '../types'

const Blog = ({
  data,
}: PageProps<{ blogPosts: INodes<IMdx<BlogPostFrontmatter>> }>) => {
  const posts = data.blogPosts.nodes

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="container mx-auto my-8">
        {/* <h2 className="text-2xl font-bold mb-2">Blog</h2> */}
        <BlogPosts posts={posts} />
      </div>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    blogPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { show: { eq: true } } }
    ) {
      nodes {
        excerpt
        fields {
          urlpath
        }
        frontmatter {
          title
          slug
          date(formatString: "MMMM DD, YYYY")
          description
        }
      }
    }
  }
`
